import React, { useContext, useState, useRef } from "react";
import { LogoutService, ImgUrl } from "../services";
import GView from "../Elements/GView";
import styled from "styled-components";
import ChangePassword from "../forms/ChangePassword";
import EditProfile from "../forms/editProfile";
import ForgotPassword from "../forms/forgotPassword";
import SignUp from "../forms/signup";
import SignIn from "../forms/login";
import { Link } from "react-router-dom";
import GAvtar from "../Elements/GAvtar";
import Modal from "react-modal";
import { useDetectOutsideClick } from "../DataFactory/useDetectOutsideClick";
import { ReactComponent as OfferIcon } from "../assets/images/offers.svg";
import { ReactComponent as UserIcon } from "../assets/images/user.svg";
// import GConfirmDialog from "../gComponents/gConfirmDialog";
import { SettingsContext } from "../DataFactory/useSettings";
import GText from "../Elements/GText";

const Header = styled.header`
  background: #fff;
  box-shadow: -1px -1px 10px #bbbbbb5c;
  box-sizing: border-box;
  // padding: 6px 12px;
  z-index: 9;
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  display: block;

  a {
    padding: 0 10px;
    text-decoration: none;
    color: #333;
    display: flex;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 7px;
    }
  }
`;

const LinkDropdown = styled.div`
  position: absolute;
  background: #fff;
  right: 15px;
  top: 58px;
  min-width: 200px;
  box-shadow: 0 0 13px #c1bebe8f;
  border-top: 3px solid ${({ theme }) => theme.body};
  padding: 10px 0;

  &::before {
    content: "";
    position: absolute;
    background: #fff;
    border-color: ${({ theme }) => theme.body};
    border: 1px solid ${({ theme }) => theme.body};
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-width: 3px;
    padding: 7px;
    margin-left: -10px;
    transform: rotate(45deg);
    top: -10px;
    right: 30px;
    z-index: 1;
  }
  ul {
    display: block;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    li {
      a {
        padding: 10px 20px;
        text-decoration: none;
        color: #222;
        cursor: pointer;
        display: block;
        text-align: left;

        &:hover {
          background: #f4f5f9;
          color: #000;
        }
      }
    }
  }
`;

const HeaderView = () => {
  const { settings } = useContext(SettingsContext);
  const dropdownRef = useRef(null);
  const [menuOpen, setMenuOpen] = useDetectOutsideClick(dropdownRef, false);
  const [cpvisible, setCpvisible] = React.useState(false);
  const [epvisible, setEpvisible] = React.useState(false);
  const [fpvisible, setFpvisible] = React.useState(false);
  const [signupvisible, setSignupvisible] = React.useState(false);
  const [signinvisible, setSigninvisible] = useState(false);

  console.log(settings);

  const customStyles = {
    content: {
      width: "400px",
    },
  };

  const handleLogout = () => {
    LogoutService("c/logout")
      .then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          console.log(response);
          localStorage.setItem("wAccessToken", "");
          localStorage.setItem("wRefreshToken", "");
          localStorage.setItem("wExpiresIn", 0);
          window.location = "/";
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Header style={{ minHeight: "40px" }}>
      <div
        className="main-content"
        style={{ marginTop: "0", marginBottom: "0" }}
      >
        <GView
          style={{ display: "flex", padding: "24px 0", alignItems: "center" }}
        >
          <Link
            to="/"
            style={{
              display: "inline-flex",
              height: "100%",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            {settings?.web?.web_logo ? (
              <img
                src={ImgUrl("web") + "/" + settings?.web?.web_logo}
                className="header-logo"
                alt={settings?.project_name}
                // onError={addDefaultLogo}
                style={{ maxWidth: "180px", width: "80%" }}
              />
            ) : (
              <GText g2="true" bold theme text={"HOME"} />
            )}
          </Link>
          <GView style={{ marginLeft: "auto" }}>
            <GView
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              {window.innerWidth > 767 && (
                <Link to={{ pathname: "/offers", state: { title: "Offers" } }}>
                  <OfferIcon /> Offers
                </Link>
              )}
              {settings ? (
                !settings?.user?.id ? (
                  <a
                    onClick={() => setSigninvisible(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <UserIcon />
                    Login
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      setMenuOpen(true);
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <GAvtar
                      title={settings?.user?.title}
                      width={"40px"}
                      height={"40px"}
                      src={
                        settings?.user?.thumb_photo
                          ? ImgUrl("user") + "/" + settings?.user?.thumb_photo
                          : ""
                      }
                      imgType="user"
                      alt={settings?.user?.title}
                    />
                    &nbsp;&nbsp;
                    <GText
                      text={settings?.user?.title}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "100px",
                        textAlign: "left",
                      }}
                    />
                  </a>
                )
              ) : (
                ""
              )}

              {menuOpen === true ? (
                <LinkDropdown ref={dropdownRef}>
                  <ul>
                    <li>
                      <Link
                        to={{
                          pathname: "/profile/bookings",
                          state: { title: "Bookings" },
                        }}
                        onClick={() => setMenuOpen(false)}
                      >
                        Manage Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: "/profile/bookings",
                          state: { title: "Bookings" },
                        }}
                        onClick={() => setMenuOpen(false)}
                      >
                        Bookings
                      </Link>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          setCpvisible(true);
                          setMenuOpen(false);
                        }}
                      >
                        Change Password
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          setMenuOpen(false);
                          //setDialogOpen(true);
                          handleLogout();
                        }}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </LinkDropdown>
              ) : (
                ""
              )}
            </GView>
          </GView>
        </GView>
      </div>
      <Modal
        isOpen={signinvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSigninvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <SignIn
          signinvisible={signinvisible}
          setSigninvisible={setSigninvisible}
          setSignupvisible={setSignupvisible}
          setFpvisible={setFpvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={signupvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <SignUp
          signupvisible={signupvisible}
          setSignupvisible={setSignupvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={epvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setEpvisible(false)}
        style={{
          content: {
            width: "380px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Example Modal"
      >
        <EditProfile epvisible={epvisible} setEpvisible={setEpvisible} />
      </Modal>

      <Modal
        isOpen={cpvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCpvisible(false)}
        style={{
          content: {
            width: "375px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "310px",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Example Modal"
      >
        <ChangePassword cpvisible={cpvisible} setCpvisible={setCpvisible} />
      </Modal>

      <Modal
        isOpen={fpvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFpvisible(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <ForgotPassword
          fpvisible={fpvisible}
          setFpvisible={setFpvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>
    </Header>
  );
};

export default HeaderView;
