import React, { useEffect, useState } from "react";

const UserSearchInfoContext = React.createContext();

const UserDefaultInfo = ({ children }) => {
  const [searchInfo, setSearchInfo] = useState({}); // line B - setting the initial cart
  console.log(searchInfo);

  useEffect(() => {
    let info = localStorage.getItem("search_info");

    console.log(info);
    info = info ? JSON.parse(info) : info;
    console.log(info);

    if (!info) {
      localStorage.setItem("search_info", JSON.stringify({ guests: "2" }));
    } else {
      setSearchInfo(info);
    }
  }, []);

  const updateInfo = (info) => {
    setSearchInfo(info);
    localStorage.setItem("search_info", JSON.stringify(info));
  };

  return (
    <UserSearchInfoContext.Provider
      value={{ searchInfo, setSearchInfo, updateInfo }}
    >
      {children}
    </UserSearchInfoContext.Provider>
  );
};

export { UserDefaultInfo, UserSearchInfoContext };
