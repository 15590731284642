import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../Elements/GButton";

export default function CancelReasons(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [orderCancelReasons, setOrderCancelReasons] = useState([]);

  useEffect(() => {
    console.log(props);
    function getCancelReasons() {
      ApiService({ method: "GET", route: "c/cancel-reasons?user_type=1" }).then(
        (response) => {
          console.log("r" + response.data.status_code);
          response.data.data.push({ title: "Other" });
          setOrderCancelReasons(response.data.data);
        }
      );
    }
    getCancelReasons();
  }, []);

  const initialValues = {
    cancel_reason: "",
    other_reason: "",
  };

  const onSubmit = (values) => {
    console.log(values);
    if (values.cancel_reason !== "Other") {
      delete values.other_reason;
    } else {
      values.cancel_reason = values.other_reason;
    }

    ApiService({
      method: "PUT",
      route: "c/order/cancel/" + props.linkedId,
      body: values,
    }).then((response) => {
      console.log("r" + response.data.status_code);
      if (response.data.status_code === 1) {
        props.setOcrVisible(false);
        props.onSubmit();
      }
      enqueueSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    cancel_reason: Yup.string().required("Required"),
    other_reason: Yup.string().when("cancel_reason", {
      is: "Other",
      then: Yup.string()
        .min(5, "Other Reason must be at least 5 characters")
        .required("Required"),
    }),
  });

  if (props.ocrvisible === false) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setOcrVisible(false)}
        />
        Cancel Order
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form>
              <label style={{ margin: "15px 15px 5px" }}>
                Please choose the cancellation reason below
              </label>
              {orderCancelReasons.length > 0 ? (
                <FormikControl
                  control="radio"
                  name="cancel_reason"
                  options={orderCancelReasons}
                  key_title="title"
                  key_value="title"
                  custom={true}
                  divstyle={{ display: "block", padding: "5px 0" }}
                />
              ) : (
                ""
              )}
              {values.cancel_reason === "Other" && (
                <FormikControl
                  control="input"
                  as="textarea"
                  placeholder="Type reason here"
                  name="other_reason"
                />
              )}

              <br />
              <GButton
                variant="condensed"
                children="Cancel Order"
                style={{ margin: "0 15px", width: "93%" }}
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
