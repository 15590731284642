import React, { useState, useEffect } from "react";
import styled from "styled-components";
import WalletImg from "../assets/images/piggy.png";
import GText from "../Elements/GText";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import { ReactComponent as UnCheck } from "../assets/images/untick-empty.svg";

const WalletDiv = styled.div`
  padding: 15px 25px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span.active svg {
    fill: ${({ theme }) => theme.body};
    path {
      fill: ${({ theme }) => theme.body};
    }
  }

  .checkbox {
    margin-left: auto;
    font-size: 1rem;
    color: ${({ theme }) => theme.body};
  }

  .checkbox__control {
    display: inline-grid;
    width: 1em;
    height: 1em;
    border-radius: 0.25em;
    border: 0.1em solid currentColor;

    svg {
      transition: transform 0.1s ease-in 25ms;
      transform: scale(0);
      transform-origin: bottom left;
    }
  }

  .checkbox__input {
    input {
      opacity: 0;
      width: 1em;
      height: 1em;
      &:checked + .checkbox__control svg {
        transform: scale(1);
      }

      &:disabled + .checkbox__control {
        color: var(--disabled);
      }
    }
  }
`;

export default function CheckoutWallet(props) {
  const [appliedWallet, setAppliedWallet] = useState(props.useWallet);
  useEffect(() => {
    setAppliedWallet(props.useWallet);
  }, [props]);
  return (
    <WalletDiv
      onClick={() => {
        setAppliedWallet(!appliedWallet);
        props.setUseWallet(!appliedWallet);
      }}
    >
      <img
        src={WalletImg}
        alt="wallet"
        style={{ width: "35px", height: "fit-content" }}
        width="35px"
        height="auto"
      />

      <div
        style={{
          flex: "1 1 auto",
          padding: "0 15px",
        }}
      >
        <GText semi text="Wallet" />
        <GText
          g6
          dark
          text={props.text ? props.text : ""}
          style={{ padding: "2px 0" }}
        />
      </div>

      {appliedWallet == true ? (
        <span className="active">
          <Check style={{ width: "22px", height: "22px" }} />
        </span>
      ) : (
        <span>
          <UnCheck style={{ width: "22px", height: "22px", fill: "#ccc" }} />
        </span>
      )}
    </WalletDiv>
  );
}
