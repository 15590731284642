import React, { useEffect, useState, useContext, useRef } from "react";
import GText from "../Elements/GText";
import styled from "styled-components";
import GButton from "../Elements/GButton";
import GSelectbox from "../Elements/gSelectbox";
import useWindowSize from "../DataFactory/useWindowSize";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { UserLocContext } from "../DataFactory/useLocation";
import { UserSearchInfoContext } from "../DataFactory/useSearchInfo";
import GView from "../Elements/GView";
import { ReactComponent as CalendarIcon } from "../assets/images/calendar.svg";
import { ReactComponent as UserIcon } from "../assets/images/user-fill.svg";
import { ReactComponent as LocationIcon } from "../assets/images/location.svg";
import { SettingsContext } from "../DataFactory/useSettings";
import { TouchableOpacity } from "react-native-web";
import GTextButton from "../Elements/GTextButton";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import MDatePicker, { utils } from "react-modern-calendar-datepicker";
import { useDetectOutsideClick } from "../DataFactory/useDetectOutsideClick";
import CancelIcon from "../assets/images/cross.png";
import {
  getMinDate,
  getMaxDate,
  getFormattedDate,
  safeDateRange,
  getTimeArray,
  getFormattedDateRange,
} from "./utilCalendar";
import { getGuestDisplay, getGuestArray } from "./utilGuest";

const Input = styled.input`
  border: 0;
  outline: none;
  width: calc(100% - 30px);
  padding: 5px 10px;
  font-family: Open Sans, sans-serif;
  font-size: 0.95rem;
  font-weight: 600;

  &::placeholder {
    font-weight: 500;
    font-size: 15px;
  }
`;

const SearchDiv = styled.div`
  margin-top: 10px;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  border-radius: 4px;

  .innerContent {
    background: #fff;
    display: flex;
    align-items: center;
    position: relative;
    flex: 1;

    svg {
      width: 20px;
      height: 20px;
      fill: #ccc;
    }
    .DatePicker {
      z-index: 1;
    }
  }
`;

export default function HomeBannerSearch(props) {
  const unitDropdownRef = useRef(null);
  const { settings } = useContext(SettingsContext);
  const [address, setAddress] = useState("");
  const { userLocation, updateLocation } = useContext(UserLocContext);
  const { updateInfo, searchInfo } = useContext(UserSearchInfoContext);

  let multiple_units = settings?.multiple_units > 0;
  const [unitFilter, setUnitFilter] = useState(
    searchInfo?.unitFilter ||
      (multiple_units ? [{ users: Math.max(2, settings?.guest_from) }] : [])
  );
  const [unitSheetOpen, setUnitSheetOpen] = useDetectOutsideClick(
    unitDropdownRef,
    false
  );

  const { width } = useWindowSize();

  const [range, setRange] = useState(
    searchInfo?.range || { from: null, to: null }
  );

  const submitEnabled = () => {
    let valid = !userLocation?.lat || !searchInfo?.starts || !searchInfo?.ends;

    if (settings?.billing_type == "hourly") {
      valid =
        valid ||
        searchInfo?.starts?.length < 14 ||
        searchInfo?.ends?.length < 14;
    }

    return valid;
  };

  const UserCounter = ({ users, setUsers }) => {
    return (
      <GView
        style={{
          marginHorizontal: 20,
          display: "flex",
          flexWrap: width > 991 ? "nowrap" : "wrap",
          marginBottom: "15px",
        }}
      >
        {getGuestArray(settings).map((item, index) => (
          <TouchableOpacity
            key={index}
            style={{
              marginRight: 10,
              marginBottom: 10,
              height: 40,
              width: 40,
              justifyContent: "center",
              borderRadius: 20,
              borderWidth: 1,
              borderColor: users == item ? "#222" : "#bbb",
            }}
            onPress={() => setUsers(item)}
          >
            <GText
              bold={users == item}
              text={item}
              style={{
                textAlign: "center",
                color: users == item ? "#222" : "#bbb",
              }}
            />
          </TouchableOpacity>
        ))}
      </GView>
    );
  };

  useEffect(() => {
    updateInfo({
      ...searchInfo,
      unitFilter: unitFilter,
    });
  }, [unitFilter]);

  useEffect(() => {
    setAddress(userLocation?.address);
  }, [userLocation]);

  useEffect(() => {
    if (range) {
      updateInfo({
        ...searchInfo,
        ...{
          range: range,
          starts: getFormattedDate(range.from, true, settings),
          ends: getFormattedDate(range.to, true, settings),
          unitFilter: unitFilter,
        },
      });
    }
  }, [range]);

  const handleSelect = async (value) => {
    console.log(value);
    const results = await geocodeByAddress(value);
    console.log(results[0].geometry.location);
    setAddress(value);
    let location = {
      lat: results[0].geometry.location.lat(),
      lng: results[0].geometry.location.lng(),
      address: value,
    };
    console.log(location);
    updateLocation(location);
  };

  return (
    <SearchDiv
      style={Object.assign(
        {
          flexDirection: width > 991 ? "row" : "column",
          background: width > 991 ? "#fff" : "transparent",
          gap: width > 991 ? "0" : "10px",
          width:
            width > 991 && width <= 1500
              ? "85%"
              : width > 1500
              ? "75%"
              : "auto",
        },
        props.mainStyle
      )}
    >
      <GView
        className="innerContent"
        style={{
          flex: width > 991 && width <= 1500 ? "20%" : "10%",
          padding: width > 991 ? "15px 10px" : "10px",
          border: width > 991 ? "0" : "1px solid #e2e2e2",
          borderRight: width > 991 ? "2px solid #e2e2e2" : "1px solid #e2e2e2",
          width: width > 991 ? "auto" : "95%",
          minWidth:
            width > 991
              ? settings?.product == "room"
                ? "auto"
                : "280px"
              : "auto",
        }}
      >
        <LocationIcon style={{ width: "22px" }} />

        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div style={{ width: "100%", textAlign: "left" }}>
              <Input
                {...getInputProps({
                  placeholder: "City, landmark or address",
                })}
              />

              {suggestions && suggestions.length > 0 ? (
                <div
                  style={{
                    background: "#fff",
                    position: "absolute",
                    left: "0",
                    top: "58px",
                    zIndex: "8",
                    width: "100%",
                    maxWidth: "450px",
                    boxSizing: "border-box",
                    padding: "0 5px 5px",
                    border: "1px solid rgb(226, 226, 226)",
                  }}
                >
                  <ul
                    style={{
                      listStyleType: "none",
                      margin: "0",
                      maxHeight: "220px",
                      overflow: "overlay",
                      textAlign: "left",
                      display: "block",
                      padding: "0",
                    }}
                  >
                    {suggestions.map((suggestion, ind) => {
                      const style = {
                        display: "flex",
                        backgroundColor: suggestion.active
                          ? "#e2e2e2"
                          : "#ffffff",
                        marginRight: "10px",
                        cursor: "pointer",
                        color: "#2D2F33",
                        borderBottom: "1px solid #e2e2e2",
                        alignItems: "center",
                        padding: "8px 10px",
                      };
                      return (
                        <li
                          key={ind}
                          {...getSuggestionItemProps(suggestion, { style })}
                        >
                          <LocationIcon
                            style={{
                              fontSize: "18px",
                              color: "#ccc",
                              verticalAlign: "middle",
                            }}
                          />{" "}
                          <p
                            style={{
                              margin: "0",
                              marginLeft: "10px",
                              color: "#333",
                              width: "calc(100% - 30px)",
                            }}
                          >
                            {suggestion.description}
                          </p>
                          <br />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </PlacesAutocomplete>
        {userLocation?.address && (
          <img
            src={CancelIcon}
            style={{ width: "15px", cursor: "pointer" }}
            onClick={() => {
              setAddress("");
              updateLocation({});
            }}
          />
        )}
      </GView>

      <GView
        className="innerContent"
        style={{
          padding: width > 991 ? "15px 10px" : "10px",
          border: width > 991 ? "0" : "1px solid #e2e2e2",
          borderRight:
            width > 991
              ? multiple_units
                ? "2px solid #e2e2e2"
                : "0"
              : "1px solid #e2e2e2",
          width: width > 991 ? "auto" : "95%",
          flex:
            width > 991
              ? multiple_units
                ? settings?.billing_type == "hourly"
                  ? "28%"
                  : "15%"
                : "1"
              : "1",
        }}
      >
        <CalendarIcon style={{ marginRight: "10px" }} />

        {settings?.billing_type == "hourly" ? (
          <GView
            style={{
              width: "calc(100% - 30px)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CalendarHourly range={range} setRange={setRange} />
          </GView>
        ) : (
          <Calendar range={range} setRange={setRange} />
        )}
      </GView>

      {multiple_units && (
        <GView
          className="innerContent"
          style={{
            border: width > 991 ? "0" : "1px solid #e2e2e2",
            padding: width > 991 ? "15px 10px" : "10px",
            width: width > 991 ? "auto" : "95%",
            flex: width > 991 ? "15%" : "1",
          }}
        >
          <TouchableOpacity onPress={() => setUnitSheetOpen(!unitSheetOpen)}>
            <GView
              style={{
                color: "#555",
                width: "auto",
                border: "0",
                margin: "0",
                padding: "4px 2em 4px 0px",
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "600",
              }}
            >
              <GView style={{ display: "flex", alignItems: "center" }}>
                <UserIcon />
                <GText
                  semib
                  text={getGuestDisplay(unitFilter, settings)}
                  style={{ marginLeft: 10 }}
                />
                {/* <Arrow height={10} width={10} /> */}
              </GView>
            </GView>
          </TouchableOpacity>

          {unitSheetOpen === true && (
            <div
              ref={unitDropdownRef}
              style={{
                marginTop: "20px",
                backgroundColor: "white",
                padding: "25px",
                borderRadius: 10,
                borderColor: "red",
                borderWidth: 2,
                position: "absolute",
                right: width > 991 ? "0" : "-25px",
                top: width > 991 ? "40px" : "30px",
                border: "1px solid #e2e2e2",
                width: width > 991 ? "auto" : "100%",
              }}
            >
              {unitFilter.map((u, i) => (
                <>
                  <GText
                    text={
                      settings?.multiple_unit_booking == 1
                        ? `${settings?.unit_display} ${i + 1} (Guests)`
                        : "Guests"
                    }
                    style={{
                      margin: "5px 20px 20px",
                      marginLeft: 0,
                      fontSize: 17,
                    }}
                  />
                  <UserCounter
                    users={u.users}
                    setUsers={(t) => {
                      setUnitFilter(
                        unitFilter.map((a, b) =>
                          b == i ? { ...u, users: t } : a
                        )
                      );
                    }}
                  />
                </>
              ))}

              {settings?.multiple_unit_booking == 1 && (
                <GView
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <GTextButton
                    title={`Add ${settings?.unit_display}`}
                    onPress={() => {
                      setUnitFilter([...unitFilter, { users: 2 }]);
                    }}
                    style={{ margin: 20, marginRight: "auto" }}
                  />
                  {unitFilter.length > 1 && (
                    <GTextButton
                      title={`Delete ${settings?.unit_display}`}
                      onPress={() => {
                        setUnitFilter(
                          unitFilter.filter(
                            (a, b) => b != unitFilter.length - 1
                          )
                        );
                      }}
                      style={{ margin: 20, marginLeft: "auto" }}
                    />
                  )}
                </GView>
              )}
            </div>
          )}
        </GView>
      )}

      <GButton
        variant="condensed"
        disabled={submitEnabled()}
        style={{
          alignSelf: width > 991 ? "stretch" : "auto",
          borderTopRightRadius: width > 991 ? "4px" : "0",
          borderBottomRightRadius: width > 991 ? "4px" : "0",
          padding: width <= 1200 ? "0.6em 1.5em" : "0.6em 2.5em",
          marginTop: width > 991 ? "0" : "10px",
          borderRadius: width > 991 ? "0" : "5px",
        }}
        onClick={() => {
          props.onSearch();
        }}
      >
        {/* Find  {settings?.product_display} */}
        Search
      </GButton>
    </SearchDiv>
  );
}

const CalendarHourly = ({ range, setRange }) => {
  const { isBeforeDate } = utils();
  const minimumDate = getMinDate();
  const maxDate = getMaxDate();
  const { settings } = useContext(SettingsContext);

  const renderCustomInput = (ref, date) => (
    <TouchableOpacity ref={ref}>
      {date ? (
        <GText semib text={`${date.day}/${date.month}/${date.year}`} />
      ) : (
        <GText text={"Select"} />
      )}
    </TouchableOpacity>
  );

  useEffect(() => {
    if (range.from && range.to && isBeforeDate(range.to, range.from)) {
      setRange({ ...range, to: range.from });
    }
  }, [range.from]);

  return (
    <>
      {/* DATE FROM */}
      <GView style={{ padding: "0 5px" }}>
        <GText text="From" style={{ textAlign: "left" }} />
        <MDatePicker
          colorPrimary={settings?.web?.web_color}
          value={range.from}
          onChange={(s) => {
            setRange({
              ...range,
              from: { ...s },
              to: range.to?.day ? { ...range.to } : null,
            });
          }}
          renderInput={({ ref }) => renderCustomInput(ref, range.from)}
          minimumDate={minimumDate}
          maximumDate={maxDate}
          calendarClassName="responsive-calendar"
          calendarPopperPosition="bottom"
        />
      </GView>
      <GView style={{ position: "relative" }}>
        <GSelectbox
          placeholder="Choose"
          defaultvalue={range?.from?.time || ""}
          data={getTimeArray(range, "from")}
          id="time"
          option_key="title"
          option_value="value"
          onChange={(time) => {
            console.log(time);
            setRange({
              ...range,
              from: { ...range.from, time },
              to: range.to?.day ? { ...range.to } : null,
            });
          }}
          style={{
            color: "#555",
            width: "auto",
            border: "0",
            margin: "0",
            padding: "4px 2em 4px 5px",
            fontFamily: "Open Sans, sans-serif",
            fontWeight: "600",
          }}
          arrowStyle={{
            position: "absolute",
            right: "10px",
            top: "8px",
            paddingRight: "0",
            paddingLeft: "5px",
          }}
        />
      </GView>

      {/* DATE TO */}
      <GView style={{ padding: "0 5px" }}>
        <GText text="To" style={{ textAlign: "left" }} />
        <MDatePicker
          colorPrimary={settings?.web?.web_color}
          value={range.to?.day ? range.to : null}
          onChange={(s) => setRange({ ...range, to: s })}
          renderInput={({ ref }) => renderCustomInput(ref, range.to)}
          minimumDate={range.from}
          maximumDate={maxDate}
          calendarClassName="responsive-calendar"
          calendarPopperPosition="bottom"
        />
      </GView>

      <GSelectbox
        placeholder="Choose"
        defaultvalue={range?.to?.time}
        data={getTimeArray(range, "to")}
        id="time"
        option_key="title"
        option_value="value"
        onChange={(time) => setRange({ ...range, to: { ...range.to, time } })}
        style={{
          color: "#555",
          width: "auto",
          border: "0",
          margin: "0",
          padding: "4px 1.5em 4px 5px",
          fontFamily: "Open Sans, sans-serif",
          fontWeight: "600",
        }}
        arrowStyle={{ position: "absolute", right: "10px" }}
      />
    </>
  );
};

const Calendar = ({ range, setRange }) => {
  const { isBeforeDate } = utils();
  const { settings } = useContext(SettingsContext);
  const minimumDate = getMinDate();
  const maxDate = getMaxDate();
  const [formatted, setFormatted] = useState(null);

  const renderCustomInput = ({ ref }) => (
    <TouchableOpacity ref={ref}>
      {formatted ? <GText semib text={formatted} /> : <GText text={"Select"} />}
    </TouchableOpacity>
  );

  useEffect(() => {
    setFormatted(getFormattedDateRange(range));
  }, [range]);

  useEffect(() => {
    if (range.from && range.to && isBeforeDate(range.to, range.from)) {
      setRange({ ...range, to: range.from });
    }
  }, [range.from]);

  return (
    <GView style={{ padding: "0 10px" }}>
      <MDatePicker
        colorPrimary={settings?.web?.web_color}
        colorPrimaryLight={settings?.web?.web_color + "22"}
        value={range}
        onChange={(range) => setRange(safeDateRange(range))}
        renderInput={renderCustomInput}
        minimumDate={minimumDate}
        maximumDate={maxDate}
        calendarClassName="responsive-calendar"
        calendarPopperPosition={"bottom"}
      />
    </GView>
  );
};
