import React, { useState, useContext, useEffect } from "react";
import { ApiService } from "../services";
import HomeBannerSearch from "../Components/homeBannerSearch";
import { SettingsContext } from "../DataFactory/useSettings";
import { UserLocContext } from "../DataFactory/useLocation";
import { UserSearchInfoContext } from "../DataFactory/useSearchInfo";
import GView from "../Elements/GView";
import ItemView from "../Elements/gItemListView";
import useWindowSize from "../DataFactory/useWindowSize";
import ItemFiltersView from "../Components/itemFilters";
import GText from "../Elements/GText";
import useDataFctory from "../useDataFactory";
import { useSnackbar } from "notistack";
import Placeholder from "../Placeholders/Placeholder";
import { GCardShimmer } from "../Elements/gShimmer";
import GButton from "../Elements/GButton";
import { useNavigate } from "react-router-dom";
import ItemFilterPopup from "../Components/itemFilterPopup";
import Modal from "react-modal";
import { ReactComponent as FilterIcon } from "../assets/images/filter.svg";

const ItemList = () => {
  const navigate = useNavigate();
  const { settings } = useContext(SettingsContext);
  const { userLocation } = useContext(UserLocContext);
  const { searchInfo } = useContext(UserSearchInfoContext);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { width } = useWindowSize();
  const [ifvisible, setIFVisible] = useState(false);
  const {
    loading,
    Shimmer,
    data: filtersData,
  } = useDataFctory("product_filters", false);
  const [itemsData, setItemsData] = useState({
    data: [],
  });
  const [page, setPage] = useState("1");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedFiltersData, setSelectedFiltersData] = useState([]);

  useEffect(() => {
    setSelectedFiltersData([...filtersData]);
  }, [filtersData]);

  function getItems() {
    let sendData = {
      lat: userLocation?.lat,
      lng: userLocation?.lng,
      starts: searchInfo?.starts,
      ends: searchInfo?.ends,
      units: searchInfo?.unitFilter,
    };
    if (settings?.multiple_units <= 0) {
      delete sendData.units;
    }
    console.log({ ...sendData, ...selectedFilters });
    ApiService({
      method: "Post",
      route: "c/products?page=" + page,
      body: { ...sendData, ...selectedFilters },
    })
      .then((response) => {
        console.log(response);

        if (response.status === 26) {
          enqueueSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setItemsData(response.data.data);
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    console.log(selectedFilters);
    if (page == 1) {
      getItems();
    } else {
      setPage("1");
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (page > 1) {
      let sendData = {
        lat: userLocation?.lat,
        lng: userLocation?.lng,
        starts: searchInfo?.starts,
        ends: searchInfo?.ends,
        units:
          settings.multiple_units == 0 ? [] : [{ users: searchInfo?.guests }],
      };

      if (settings.multiple_units <= 0) {
        delete sendData.units;
      }

      console.log(sendData);
      ApiService({
        method: "Post",
        route: "c/products?page=" + page,
        body: sendData,
      })
        .then((response) => {
          console.log(response);

          if (response.status === 26) {
            enqueueSnackbar(
              "NETWORK FAILED. Please check your internet connection."
            );
            return;
          } else {
            if (response?.data?.status_code === 1) {
              itemsData.data = [...itemsData.data, response.data.data];
              setItemsData({ ...itemsData });
            } else {
              enqueueSnackbar(response?.data?.message);
            }
          }

          setIsLoading(false);
          setBtnDisabled(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getItems();
    }
  }, [page]);

  return (
    <GView
      className="main-content"
      style={{ padding: width > 767 ? "20px 50px" : "0 15px" }}
    >
      <HomeBannerSearch
        mainStyle={{
          border: "1px solid #e2e2e2",
          marginBottom: "20px",
          padding: width > 991 ? "0" : "10px",
          width: "100%",
        }}
        onSearch={() => {
          if (page == 1) {
            getItems();
          } else {
            setPage("1");
          }
        }}
      />

      <GView style={{ display: "flex", paddingTop: "20px" }}>
        {selectedFiltersData && selectedFiltersData.length > 0 && width > 767 && (
          <GView
            style={{
              flex:
                width > 1200 && width <= 1550
                  ? "25%"
                  : width > 1550
                  ? "20%"
                  : "30%",
            }}
          >
            <ItemFiltersView
              data={selectedFiltersData}
              applyBtn={true}
              submitBtn={false}
              onSubmit={(sValues) => {
                console.log(sValues);

                let fData = {};
                selectedFiltersData.map((f) => {
                  f.selectedVal = sValues[f.identifier] || "";
                  console.log(sValues[f.identifier]);
                  fData[f?.identifier?.toString()] =
                    sValues[f.identifier] || "";
                  //   }
                });

                console.log(fData);
                setSelectedFiltersData([...selectedFiltersData]);
                setSelectedFilters(fData);
              }}
            />
          </GView>
        )}
        <GView
          style={{
            flex:
              filtersData && filtersData.length > 0
                ? width > 1200 && width <= 1550
                  ? "75%"
                  : width > 1550
                  ? "80%"
                  : width <= 767
                  ? "100%"
                  : "70%"
                : "100%",
          }}
        >
          {isLoading === true ? (
            <GCardShimmer />
          ) : (
            <>
              <GView style={{ display: "flex", alignItems: "center" }}>
                <GText
                  g3
                  bold
                  text={`${itemsData?.total || ""} ${
                    settings?.product_display
                  }`}
                />
                {width <= 767 && (
                  <a
                    style={{
                      cursor: "pointer",
                      marginLeft: "auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => setIFVisible(true)}
                  >
                    <FilterIcon
                      style={{
                        width: "18px",
                        height: "18px",
                      }}
                    />
                    <GText
                      semi
                      text={"Filters"}
                      style={{
                        paddingLeft: "5px",
                      }}
                    />
                  </a>
                )}
              </GView>
              {itemsData.data.length === 0 ? (
                <Placeholder type={"item"} />
              ) : (
                <>
                  <GView
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        width <= 350
                          ? "repeat(auto-fit, minmax(300px, 1fr))"
                          : width > 1200 && width <= 1600
                          ? itemsData?.data.length > 1
                            ? "repeat(auto-fit, minmax(300px, 1fr))"
                            : "1fr 1fr"
                          : width > 1600
                          ? itemsData?.data.length > 1
                            ? "repeat(auto-fit, minmax(300px, 1fr))"
                            : "1fr 1fr 1fr"
                          : "repeat(auto-fit, minmax(300px, 1fr))",
                      gridGap: "20px",
                      padding: "20px 0",
                    }}
                  >
                    {itemsData.data.map((item, i) => {
                      return (
                        <ItemView
                          key={i}
                          data={item}
                          settings={settings}
                          onSelectItem={() => {
                            navigate(
                              `/${settings?.product_display?.toLowerCase()}/${
                                item.id
                              }${
                                settings?.multiple_units == 2
                                  ? `?puid=${item?.product_units[0]?.id}`
                                  : ""
                              }`,
                              {
                                state: {
                                  unit_id:
                                    item?.product_units &&
                                    item?.product_units.length > 0
                                      ? item?.product_units[0]?.id
                                      : "",
                                  title: item?.custom_fields?.title,
                                },
                              }
                            );
                          }}
                        />
                      );
                    })}
                  </GView>

                  {itemsData.next_page_url && (
                    <GButton
                      variant="condensed"
                      loading={btnDisabled}
                      children={"Load More"}
                      type="button"
                      onClick={() => {
                        setBtnDisabled(true);
                        setPage(page + 1);
                      }}
                      style={{
                        display: "flex",
                        margin: "10px auto",
                        minWidth: "150px",
                      }}
                    />
                  )}
                </>
              )}
            </>
          )}
        </GView>
      </GView>

      {width <= 576 ? (
        <Modal
          isOpen={ifvisible}
          className="modal modalBottom"
          overlayClassName="modal-overlay"
          onRequestClose={() => setIFVisible(false)}
          style={{
            content: {
              width: "100vw",
              top: "50%",
              left: "0",
              right: "0",
              bottom: "0",
              height: "auto",
            },
          }}
          contentLabel="Filter Modal"
        >
          <ItemFilterPopup
            popup={true}
            ifvisible={ifvisible}
            setIFVisible={setIFVisible}
            data={selectedFiltersData}
            setSelectedFiltersData={setSelectedFiltersData}
            setSelectedFilters={setSelectedFilters}
          />
        </Modal>
      ) : (
        ""
      )}
    </GView>
  );
};

export default ItemList;
