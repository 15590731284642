import React, { useState } from "react";
import "../assets/css/ratingStyles.css";

const Star = ({ selected = false, onClick = (f) => f }) => (
  <div className={selected ? "star selected" : "star"} onClick={onClick} />
);

const StarRating = ({ totalStars, selectedStars, selected, onSelect }) => {
  const [starsSelected, setSelectedStars] = useState(selectedStars || 0);
  return (
    <div className="star-rating">
      {[...Array(totalStars)].map((n, i) => (
        <Star
          key={i}
          selected={i < starsSelected}
          onClick={() => {
            if (selected === true) {
              setSelectedStars(i + 1);
              onSelect(i + 1);
            }
          }}
        />
      ))}
    </div>
  );
};
export default StarRating;
