import React, { useState, useContext } from "react";
import HomeBannerSearch from "../Components/homeBannerSearch";
import { SettingsContext } from "../DataFactory/useSettings";
import GView from "../Elements/GView";
import HomeContent from "../Components/homeContent";
import { ImgUrl } from "../services";
import GText from "../Elements/GText";
import ItemView from "../Elements/gItemListView";
import useWindowSize from "../DataFactory/useWindowSize";
import PopularItemData from "../jsonData/itemJson.json";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const { settings } = useContext(SettingsContext);
  const { width } = useWindowSize();

  const customStyles = {
    content: {
      width: "400px",
    },
  };

  return (
    <>
      <GView
        className="main-content"
        style={{ padding: width > 767 ? "20px 0" : "20px" }}
      >
        <GView
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "auto",
            minHeight: "400px",
            backgroundImage: `url(${
              ImgUrl("website") + "/" + settings?.web?.web_home_header
            })`,
            backgroundSize: "cover",
            backgroundPosition: "100%",
            backgroundRepeat: "no-repeat",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          <GText
            g2="true"
            bold
            text={settings?.web?.web_home_title}
            style={{
              color: settings?.web?.web_home_header ? "#fff" : "#000",
              marginTop: "auto",
              textShadow: settings?.web?.web_home_header
                ? "0.5px 0.5px #f9f9f9"
                : "none",
              fontFamily: "Open Sans, sans-serif",
              fontSize: width > 767 ? "28px" : "24px",
            }}
          />
          {settings?.web?.web_home_desc && (
            <GText
              text={settings?.web?.web_home_desc}
              style={{
                padding: "10px 0",
                fontSize: "16px",
                color: settings?.web?.web_home_header ? "#fff" : "#000",
                textShadow: settings?.web?.web_home_header
                  ? "0.5px 0.5px #f9f9f9"
                  : "none",
              }}
            />
          )}

          <HomeBannerSearch
            onSearch={() => {
              navigate(`/${settings?.product_display?.toLowerCase()}`, {
                state: { title: settings?.product_display },
              });
            }}
          />
        </GView>

        {/* {PopularItemData && PopularItemData.length > 0 && (
          <>
            <GText
              g2={"true"}
              bold
              text={`Popular ${settings?.product_display}`}
              style={{ margin: "40px 0 10px" }}
            />

            <GView
              style={{
                display: "grid",
                gridTemplateColumns:
                  width <= 350
                    ? "repeat(auto-fit, minmax(300px, 1fr))"
                    : "repeat(auto-fit, minmax(350px, 1fr))",
                gridGap: "20px",
                padding: "20px 0",
              }}
            >
              {PopularItemData.map((item, i) => {
                return <ItemView data={item} key={i} settings={settings} />;
              })}
            </GView>
          </>
        )}

        <hr
          style={{
            margin: "20px 0",
            borderTop: "0px",
            borderBottom: "1px solid #eee",
          }}
        /> */}

        <HomeContent
          data={settings?.plugins?.homepage}
          content={
            settings?.homepage_content
              ? JSON.parse(settings?.homepage_content)
              : settings?.homepage_content
          }
        />
      </GView>
    </>
  );
};

export default Home;
