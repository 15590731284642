import React, { useState } from "react";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import GButton from "../Elements/GButton";

export default function StartCheckListView(props) {
  if (props.startCheckListVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setStartCheckListVisible(false)}
        />
        Disclaimer
      </div>
      <div className="modal-body">
        <div
          style={{ fontFamily: "Open Sans, sans-serif" }}
          dangerouslySetInnerHTML={{ __html: props.settings?.disclaimer }}
        ></div>

        <GButton
          variant="condensed"
          children="Continue"
          onClick={() => props.onClick()}
          style={{
            width: "calc(100% - 20px)",
            marginTop: "20px",
            fontFamily: "Open Sans, sans-serif",
            fontWeight: "600",
          }}
        ></GButton>
      </div>
    </React.Fragment>
  );
}
