import React from "react";
import GOffers from "../Elements/gOffers";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import useDataFctory from "../useDataFactory";
import useWindowSize from "../DataFactory/useWindowSize";

export default function Offers() {
  const { width } = useWindowSize();
  const { loading, Shimmer, Placeholder, data } = useDataFctory(
    "coupon",
    false
  );

  return (
    <div className="main-content">
      <GText g2="true" bold text={"Offers"} />
      <GView
        style={{
          boxShadow: "0 0 10px #cccccc85",
          padding: "20px",
          margin: "20px 0",
        }}
      >
        {loading === true ? (
          <Shimmer />
        ) : data.data.length === 0 ? (
          <Placeholder />
        ) : (
          <GView
            style={{
              display: "grid",
              gridTemplateColumns:
                width <= 350
                  ? "repeat(auto-fit, minmax(300px, 1fr))"
                  : "repeat(auto-fit, minmax(350px, 1fr))",
              gridGap: "20px",
            }}
          >
            {data.data.map((c, i) => {
              return <GOffers data={c} key={i} copy={true} />;
            })}
          </GView>
        )}
      </GView>
    </div>
  );
}
