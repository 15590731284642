import React, { useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "notistack";
import GButton from "../Elements/GButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";

export default function RedeemCode(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);

  const initialValues = {
    code: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    console.log(values);
    ApiService({
      method: "POST",
      route: "c/redeem-invitation",
      body: values,
    }).then((response) => {
      console.log("r" + response.data.status_code);
      if (response.data.status_code === 1) {
        props.setRedeemCodeVisible(false);
        props.onRedeem();
      }
      setBtnLoading(false);
      enqueueSnackbar(response.data.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    code: Yup.string().required("Required"),
  });

  if (props.redeemCodeVisible === false) {
    return null;
  }
  return (
    <div className="modal-body">
      <h4 style={{ margin: "5px 10px" }}>Redeem Invite Code</h4>
      <p
        style={{
          fontSize: "14px",
          margin: "10px",
          lineHeight: "20px",
          color: "#333",
        }}
      >
        {" "}
        Please enter the referral code below.
      </p>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur={false}
      >
        {({ values, isValid, setFieldValue }) => (
          <Form>
            <FormikControl
              control="input"
              type="text"
              placeholder="Enter code..."
              name="code"
              style={{ height: "45px" }}
            />
            <br />
            <GButton
              variant="condensed"
              // disabled={!isValid}
              style={{
                margin: "0 15px",
                width: "92%",
              }}
              children="Submit"
              type="submit"
              loading={isBtnLoading}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
