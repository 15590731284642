import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";
import styled from "styled-components";
import Tick from "../assets/images/untick-sq.png";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import { ReactComponent as UnCheck } from "../assets/images/untick.svg";

const CheckBoxDiv = styled.div`
  padding: 8px 0;

  & input[type="checkbox"] {
    display: none;

    &:checked + label span {
      svg {
        fill: ${({ theme }) => theme.body};
        // border: 1px solid ${({ theme }) => theme.body};
        color: #fff;
      }
    }
  }

  & label {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #555;
    margin: 0;

    span {
      // border: 1px solid #ccc;
      height: 16px;
      margin-left: 3px;
      // padding: 0.5px 1px 1.5px;
      border-radius: 2px;
      margin-right: 15px;
      svg {
        width: 1rem;
        height: 1rem;
        color: #ccc;
      }
    }
  }
`;

function checkBoxes(props) {
  const { label, name, key_title, key_value, options, ...rest } = props;
  return (
    <div className="form-control" style={{ padding: "10px 0" }}>
      <label style={Object.assign({}, props.mainLabelStyle)}>
        {label}
        {props.type === "variant" && props.isRequired === true ? "*" : ""}
      </label>
      <Field name={name} {...rest}>
        {({ field }) => {
          console.log(field);
          return options.map((option, index) => {
            return (
              <CheckBoxDiv key={index} style={props.divstyle}>
                <input
                  type="checkbox"
                  id={"check_" + option[key_title]}
                  {...field}
                  value={JSON.stringify(option[key_value])}
                  checked={field.value.includes(
                    JSON.stringify(option[key_value])
                  )}
                />

                <label
                  className="radioLabelStyle"
                  htmlFor={"check_" + option[key_title]}
                  style={Object.assign({}, props.labelStyle)}
                >
                  {field.value.includes(JSON.stringify(option[key_value])) ? (
                    <span>
                      <Check
                        htmlFor={"check_" + option[key_title]}
                        style={{ fontSize: "1rem" }}
                      />
                    </span>
                  ) : (
                    <span>
                      <UnCheck style={{ fontSize: "1rem" }} />
                      {/* <img src={Tick} alt="Check" htmlFor={"check_" + option[key_title]} /> */}
                    </span>
                  )}
                  {option[key_title]}

                  {props.type == "variant"
                    ? props.priceType == "1" || props.priceType === "2"
                      ? " (" +
                        (props.priceType == "1" ? "+ " : "") +
                        props.settings?.currency_symbol +
                        option.price +
                        ")"
                      : ""
                    : ""}
                </label>
              </CheckBoxDiv>
            );
          });
        }}
      </Field>
      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default checkBoxes;
