/* global google */
import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete from "react-places-autocomplete";
import locationMarker from "../assets/images/map-marker.png";
import locationIcon from "../assets/images/map-marker.svg";

class LocationSearchInput extends React.Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = { address: props.value || "" };
    this.placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address, placeId) => {
    this.setState({ address });

    const request = {
      placeId: placeId,
      fields: ["name", "geometry"],
    };
    this.placesService.getDetails(request, (place, status) => {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        this.props.onPlaceChanged(place);
      }
    });
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div
            className="autocomplete-container"
            style={{ position: "relative" }}
          >
            <input
              {...getInputProps({
                placeholder: this.props.placeholder || "Search Places ...",
                className: "location-search-input",
              })}
              style={{
                width: "100%",
                border: "1px solid #ddd",
                height: "36px",
                outline: "0",
                padding: "8px 10px",
                boxSizing: "border-box",
              }}
            />
            <div
              className="autocomplete-dropdown-container"
              style={{
                border: suggestions.length > 0 ? "1px solid #e9e9e9" : "0",
                position: "absolute",
                zIndex: "99999999",
                width: "100%",
                borderTop: "0px",
                boxSizing: "border-box",
              }}
            >
              {loading && <div></div>}
              {suggestions.map((suggestion, i) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                console.log("i==" + i);
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#fafafa",
                      cursor: "pointer",
                      borderBottom: "1px solid #e2e2e2",
                      padding: "5px",
                      fontSize: "13px",
                      display: "flex",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      borderBottom: "1px solid #e2e2e2",
                      padding: "5px",
                      fontSize: "13px",
                      display: "flex",
                    };
                return (
                  <div
                    key={i}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <img
                      src={locationMarker}
                      alt=""
                      style={{
                        width: "20px",
                        opacity: "0.6",
                        paddingRight: "5px",
                        verticalAlign: "text-top",
                      }}
                    />
                    <span
                      style={{
                        width: "90%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {suggestion.description}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

class MapContainer extends React.Component {
  // constructor(props) {
  //   console.log(props);
  //   super(props);
  state = {
    address: this.props.address || "",
    places: {},
    markers: this.props.centerLocation || {},
    marker: require("../assets/images/map-marker.svg"),
    city: "",
    state: "",
    country: "",
    zipcode: "",
    area: "",
  };
  showPlace = this.showPlace.bind(this);
  mapRef = React.createRef();
  geocoder = new google.maps.Geocoder();

  static getDerivedStateFromProps(props, state) {
    if (props.currentRow !== state.lastRow) {
      return {
        address: props.address || "",
        markers: props.centerLocation || {},
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  getCountry = (addressArray) => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "country" === addressArray[i].types[0]) {
        country = addressArray[i].long_name;
        return country;
      }
    }
  };

  getZipcode = (addressArray) => {
    let zipcode = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "postal_code" === addressArray[i].types[0]
      ) {
        zipcode = addressArray[i].long_name;
        return zipcode;
      }
    }
  };

  showPlace(place) {
    console.log("places" + JSON.stringify(this.state.places));
    console.log(place.geometry.location);
    if (this.props.map === true) {
      this.mapRef.current.map.setCenter(place.geometry.location);
    }
    this.geocodeAddress(this.state.places, place.geometry.location);
  }

  onMarkerDragEnd = (coord, index) => {
    console.log(coord);
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    console.log(lat);

    this.geocodeAddress(coord, latLng);
  };

  geocodeAddress(address, latlng) {
    var latlngNew = {
      lat: parseFloat(latlng.lat()),
      lng: parseFloat(latlng.lng()),
    };
    console.log(latlngNew);
    this.geocoder.geocode(
      { location: latlngNew },
      function handleResults(results, status) {
        console.log(results[0]);
        console.log(results[0].address_components);
        var addressArray = results[0].address_components;
        var city = this.getCity(addressArray);
        var area = this.getArea(addressArray);
        var state = this.getState(addressArray);
        var country = this.getCountry(addressArray);
        var zipcode = this.getZipcode(addressArray);

        var adreessComponents = {
          address: address[0]?.name || results[0].formatted_address,
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          country: country ? country : "",
          zipcode: zipcode ? zipcode : "",
        };
        this.setState(adreessComponents);

        console.log(adreessComponents);

        // this.setState({address : adreessComponents.address})
        this.props.onChange(adreessComponents, latlng, results[0]);

        if (status === google.maps.GeocoderStatus.OK) {
          console.log(status);
          if (this.props.map === true) {
            this.mapRef.current.map.setCenter(results[0].geometry.location);
          }

          this.setState((prevState) => ({
            markers: { position: results[0].geometry.location },
            places: {},
            marker: "",
          }));
          return;
        }
      }.bind(this)
    );
  }

  fetchPlaces(mapProps, map) {
    console.log(this.props);
    if (this.props?.onLoad == true) {
      console.log(mapProps);
      console.log(map);
      const { center } = map;
      const lat = center?.lat();
      const lng = center?.lng();
      console.log(lat);

      this.geocodeAddress(map, center);
    }
  }

  render() {
    return (
      <div
        className="map-container"
        key={this.props.type}
        style={{ padding: this.props.padding === true ? "4px 15px" : "" }}
      >
        {this.props.label ? <label>{this.props.label}</label> : ""}
        {this.props.search === true ? (
          <LocationSearchInput
            onPlaceChanged={this.showPlace}
            value={this.state.address}
            placeholder={this.props.placeholder}
          />
        ) : (
          ""
        )}
        {this.props.map === true ? (
          <Map
            ref={this.mapRef}
            google={this.props.google}
            className={"map"}
            zoom={16}
            initialCenter={this.props.centerLocation}
            mapTypeControl={false}
            streetViewControl={false}
            zoomControl={false}
            fullscreenControl={false}
            style={{
              height: this.props.height,
              minHeight: "200px",
              position: "relative",
            }}
            onReady={(mapProps, map) => {
              setTimeout(() => {
                this.fetchPlaces(mapProps, map);
              }, 1000);
            }}
          >
            <Marker
              position={this.state.markers.position}
              visible={true}
              draggable={this.props.search}
              onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
              icon={{
                url: locationIcon,
                // anchor: new google.maps.Point(32, 32),
                scaledSize: new google.maps.Size(40, 40),
              }}
            />
          </Map>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_KEY,
  libraries: ["places"],
})(MapContainer);
