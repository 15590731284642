import React, { useEffect, useState, useContext } from "react";
import { ApiService } from "../services";
import { ReactComponent as Send } from "../assets/images/send.svg";
import { useSnackbar } from "notistack";
import GButton from "../Elements/GButton";
import { MessageBox } from "../styledComponents/messageStyles";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import GText from "../Elements/GText";
import { SettingsContext } from "../DataFactory/useSettings";

function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  console.log(match);
  return match ? match[1] : undefined;
}

const fixedDivStyle = {
  display: "flex",
  bottom: getIOSVersion() >= 14 ? "110px" : "10px",
  right: "0",
  left: "5px",
  width: "95%",
  position: "absolute",
  paddingRight: "10px",
  borderTop: "1px solid #e2e2e2",
  background: "#fff",
  zIndex: "9",
};
const formInputStyle = {
  background: "transparent",
  border: "0",
  padding: "0.8rem",
  fontSize: "0.95rem",
  outline: "0",
  boxShadow: "none",
  width: "90%",
};

export default function OrderMesaages(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [messageVal, setMessageValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const { settings } = useContext(SettingsContext);

  useEffect(() => {
    let url =
      settings?.theme === "3" ? "c/order/taxi" : "c/orders/" + props.orderId;
    ApiService({ method: "GET", route: url })
      .then((response) => {
        console.log("gh" + response);
        if (response.status === 26) {
          enqueueSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setMessages(response?.data?.data?.order_messages || []);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    console.log(props);
    setMessages(props.data);
  }, [props]);

  function updateField() {
    if (!messageVal) {
      enqueueSnackbar("message is required");
      return;
    }
    setIsBtnDisabled(true);
    var post_note = {
      order_id: props.orderId,
      user_type: "1",
      user_id: props.settings?.user?.id,
      text: messageVal,
    };
    ApiService({ method: "POST", route: "c/order/messages", body: post_note })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status === 26) {
          enqueueSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          setIsBtnDisabled(false);
          return;
        } else {
          if (response.data.status_code === 1) {
            setMessageValue("");
            //setMessages([...messages, response.data.data]);
            props.onSubmit(response.data.data);
          }
          setIsBtnDisabled(false);
          enqueueSnackbar(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (props.omvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setOmvisible(false)}
        />
        <GText
          g4
          semi
          text={`Booking #${props.orderId}`}
          style={{ fontSize: "17px" }}
        />
      </div>
      <div
        className="modal-body"
        style={{
          height:
            getIOSVersion() >= 14
              ? "calc(100vh - 250px)"
              : "calc(100vh - 120px)",
        }}
      >
        <div
          style={{
            padding: "5px 30px 15px",
            maxHeight: "75vh",
            overflow: "overlay",
            marginBottom: "40px",
          }}
        >
          {messages.map((p) => {
            return (
              <div
                key={p.id}
                style={{ textAlign: p.user_type === 1 ? "right" : "left" }}
              >
                <MessageBox className={p.user_type === 1 ? "send" : "received"}>
                  <h5>{p.text}</h5>

                  <p>
                    {p.user_type === 1 ? "" : p.user_title + ", "}
                    {p.created}
                  </p>
                </MessageBox>
              </div>
            );
          })}
        </div>
      </div>
      <div style={fixedDivStyle}>
        <input
          type="text"
          value={messageVal}
          id="messages"
          placeholder={"Type a message.."}
          onChange={(e) => setMessageValue(e.target.value)}
          style={formInputStyle}
        />
        <GButton
          disabled={isBtnDisabled}
          variant="condensed"
          onClick={() => {
            updateField("messages");
          }}
          style={{
            borderRadius: "50%",
            color: "#fff",
            marginTop: "7px",
            height: "32px",
            width: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "1px 1px 1px 5px",
          }}
        >
          <Send
            style={{
              width: "18px",
              height: "18px",
              fill: "#fff",
              transform: "rotate(0deg)",
            }}
          />
        </GButton>
      </div>
      {getIOSVersion() >= 14 ? <div style={{ height: "80px" }}></div> : ""}
    </React.Fragment>
  );
}
