import React, { useContext } from "react";
import CloseIcon from "../assets/images/cross.png";
// import { CardHead } from "../styledComponents/cardStyles";
import ItemFiltersView from "./itemFilters";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";

export default function MobileItemFilters(props) {
  if (props.sfvisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      {/* <div className="modal-header">
        {props.popup === true && (
          <React.Fragment>
            <GText bold g4 text={"Filters"} />

            <img
              src={CloseIcon}
              className="backIcon"
              onClick={() => props.setAdvisible(false)}
              style={{
                marginLeft: "auto",
                width: "15px",
                height: "15px",
              }}
            />
          </React.Fragment>
        )}
      </div> */}

      <div
        className="modal-body"
        style={{
          padding: props.popup === true ? "0" : "0 10px 20px",
          maxHeight: "auto",
        }}
      >
        <ItemFiltersView
          submitBtn={false}
          data={props.data}
          applyBtn={true}
          isPopup={props.popup}
          onSubmit={(values) => {
            let fData = {};
            props.data.map((f) => {
              console.log(values[f.identifier]);
              f.selectedVal = values[f.identifier] || "";
              fData[f?.identifier?.toString()] = values[f.identifier] || "";
              //   }
            });
            console.log(props.data);
            props.setSelectedFiltersData([...props.data]);
            props.setSelectedFilters(fData);
            props.setIFVisible(false);
          }}
        />
      </div>
    </React.Fragment>
  );
}
