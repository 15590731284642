import React, { useState } from "react";
import GView from "../Elements/GView";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import GButton from "../Elements/GButton";
import GOffers from "../Elements/gOffers";
import useDataFctory from "../useDataFactory";

export default function OrderCouponPopup(props) {
  const { loading, Shimmer, Placeholder, data } = useDataFctory(
    "coupon",
    false,
    {}
  );
  const [couponCode, setCouponCode] = useState("");

  if (props.couponVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setCouponVisible(false)}
        />
        Apply Coupon
      </div>
      <div className="modal-body">
        {loading === true ? (
          <Shimmer />
        ) : data.data.length === 0 ? (
          <Placeholder />
        ) : (
          <React.Fragment>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="Enter Coupon code..."
                value={couponCode || ""}
                style={{
                  border: "0",
                  outline: "0",
                  background: "#f4f5f9",
                  width: "63%",
                  borderRadius: "4px",
                  padding: "12px",
                }}
                onBlur={(e) => setCouponCode(e.target.value)}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <GButton
                variant="condensed"
                children="Apply"
                style={{ marginLeft: "10px" }}
                disabled={couponCode ? false : true}
                onClick={() => {
                  console.log(couponCode);
                  props.onApplyCoupon(couponCode);
                }}
              />
            </div>
            <br />
            <GView
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
                gridGap: "20px",
              }}
            >
              {data.data.map((c, i) => {
                return (
                  <GOffers
                    key={i}
                    data={c}
                    onCopy={(coupon) => {
                      setCouponCode(coupon.code);
                    }}
                  />
                );
              })}
            </GView>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
