import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ImgUrl, addDefaultSrc } from "../services";
import GAvtar from "./GAvtar";
import GText from "./GText";

const ItemImage = styled.div`
  margin-right: 15px;
  .MuiAvatar-root {
    height: 60px;
    width: 60px;
  }
  & img {
    height: 60px;
    width: 60px;
  }
  &.headerInfo {
    .MuiAvatar-root {
      height: 50px;
      width: 50px;
      text-transform: uppercase;
      background: ${({ theme }) => theme.body};
      color: #fff;
    }
    .MuiAvatar-fallback {
      width: 85%;
      height: 85%;
    }
  }
`;

const ItemText = styled.div`
  h5 {
    margin: 5px 0;
    color: #aaa;
  }
  & .amount {
    background: ${({ theme }) => theme.body} /*#f4f5f9*/;
    color: #fff; /*#777;*/
    float: right;
    font-size: 14px;
    padding: 3px 8px;
    border-radius: 20px;
  }
  & .rating {
    background: ${({ theme }) => theme.body};
    float: right;
    font-size: 14px;
    color: #fff;
    padding: 2px 7px;
    display: flex;
    align-items: flex-end;
    border-radius: 15px;
    margin-top: -25px;
  }

  &.headerInfo {
    h4 {
      color: #000;
      font-size: 20px;
      font-weight: 500;
    }
  }
`;

function GInfo(props) {
  const [infoData, setInfoData] = useState({});

  useEffect(() => {
    setInfoData(props);
  }, [props]);

  return (
    <div
      style={Object.assign(
        {},
        { display: "inline-flex", width: "100%" },
        props.style
      )}
    >
      {infoData.imgType ? (
        <ItemImage className={infoData.className}>
          {infoData.photo &&
          infoData.imgType !== "user" &&
          infoData.imgType !== "host" ? (
            <img
              src={ImgUrl(infoData.imgType) + "/" + infoData.photo}
              alt={infoData.title}
              onError={(e) => addDefaultSrc(e, infoData.imgType)}
              style={props.imgStyle}
            />
          ) : (
            <GAvtar
              src={ImgUrl(infoData.imgType) + "/" + infoData.photo}
              title={infoData.title}
              width={props.width || ""}
              height={props.height || ""}
            />

            // <Avatar
            //   alt={infoData.title}
            //   src={ImgUrl(infoData.imgType) + "/" + infoData.photo}
            // />
          )}
        </ItemImage>
      ) : (
        ""
      )}
      <ItemText
        className={infoData.className}
        style={{ width: infoData.imgType ? "calc(100% - 65px)" : "100%" }}
      >
        {infoData.rightTitle && (
          <span className="amount">{infoData.rightTitle}</span>
        )}
        {infoData.titleTheme ? (
          <GText
            g4
            semi
            theme
            text={infoData.title}
            style={infoData.titleStyle}
          />
        ) : (
          infoData.title && (
            <GText g4 semi text={infoData.title} style={props.titleStyle} />
          )
        )}
        {infoData.subtitle && (
          <GText style={props.subtitleStyle} text={infoData.subtitle} />
        )}
        {infoData.subtitle2 ? (
          <GText
            med
            g6
            text={infoData.subtitle2}
            style={{ padding: "5px 0" }}
          />
        ) : (
          ""
        )}
        {infoData.status ? (
          <span
            className="orderStatus"
            style={{
              background: "#" + infoData.status.color1,
            }}
          >
            {infoData.status.title}
          </span>
        ) : (
          ""
        )}
        {infoData.rating ? (
          <span className="rating">★ {infoData.rating}</span>
        ) : (
          ""
        )}
      </ItemText>
    </div>
  );
}

export default GInfo;
