import React from "react";
import { Field, ErrorMessage } from "formik";
import TextErrors from "./formikTextErrors";

function selectbox(props) {
  const { label, name, key_title, key_value, options, ...rest } = props;
  return (
    <div className="form-control">
      <label htmlFor={name}>{label}</label>
      <Field as="select" id={name} name={name} {...rest}>
        <option value="">
          {props.placeholder ? props.placeholder : "Select an option"}
        </option>
        {options.map((option, ind) => {
          return (
            <option key={ind} value={option[key_value]}>
              {option[key_title]}
            </option>
          );
        })}
      </Field>
      <ErrorMessage name={name} component={TextErrors} />
    </div>
  );
}

export default selectbox;
