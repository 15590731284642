import React, { useState, useEffect } from "react";
import CouponImg from "../assets/images/coupon.png";
import ApplyCouponPopup from "./couponPopup";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import Modal from "react-modal";
import GView from "../Elements/GView";

const customStyles = {
  content: {
    top: "0",
    left: "auto",
    right: "0",
    bottom: "0",
    width: "450px",
  },
};

export default function CheckoutOrderCoupon(props) {
  const [appliedCoupon, setAppliedCoupon] = useState("");
  useEffect(() => {
    setAppliedCoupon(props.selectedCoupon);
  }, [props]);
  return (
    <GView style={{ padding: "15px 25px 20px" }}>
      <img
        src={CouponImg}
        alt="coupon"
        style={{ width: "35px", paddingRight: "15px" }}
        height="auto"
        width="35px"
      />
      {!appliedCoupon || props.isCouponValid === false ? (
        <React.Fragment>
          <GButton
            variant="linkable"
            children="Apply"
            style={{ fontSize: "14px", float: "right" }}
            onClick={() => props.setCouponVisible(true)}
          />
          <GView
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              width: "calc(100% - 110px)",
            }}
          >
            <GText semi text="Coupon" />
            <GText
              g6
              dark
              text="Choose from the available offers"
              style={{ padding: "2px 0" }}
            />
          </GView>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <GButton
            variant="linkable"
            children="Remove"
            style={{ float: "right" }}
            onClick={() => {
              setAppliedCoupon("");
              props.setSelectedCoupon("");
              props.setIsCouponValid(false);
            }}
          />
          <div style={{ display: "inline-block", verticalAlign: "middle" }}>
            <GText semi text={appliedCoupon} />
            <GText
              g6
              dark
              text="Promo Code Added"
              style={{ padding: "2px 0" }}
            />
          </div>
        </React.Fragment>
      )}

      <Modal
        isOpen={props.couponVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => props.setCouponVisible(false)}
        style={customStyles}
        contentLabel="Coupon Modal"
      >
        <ApplyCouponPopup
          couponVisible={props.couponVisible}
          applyCoupon={true}
          setCouponVisible={props.setCouponVisible}
          onApplyCoupon={(coupon) => {
            console.log(coupon);
            if (appliedCoupon && appliedCoupon === coupon) {
              setAppliedCoupon(coupon);
              props.setSelectedCoupon(...coupon);
              props.setIsCouponValid(false);
              return;
            }
            setAppliedCoupon(coupon);
            props.setSelectedCoupon(coupon);
            props.setIsCouponValid(true);
          }}
        />
      </Modal>
    </GView>
  );
}
