import React from "react";
import styled from "styled-components";
import { Skeleton } from "@mui/material";
import useWindowSize from "../DataFactory/useWindowSize";
import GView from "./GView";

const CardShimmer = styled.div`
  .MuiSkeleton-root {
    width: 100%;
    // background: #f6f7f8;

    &.MuiSkeleton-rect {
      margin: 0 8px 25px;
      display: inline-block;
      box-sizing: border-box;
      width: calc(50% - 16px);

      &.single {
        width: calc(100% - 16px);
      }
    }
    &.MuiSkeleton-text {
      margin-top: 10px;
      margin-bottom: 40px;
    }
  }
`;
const RoundShimmer = styled.div`
  display: flex;
  .MuiSkeleton-root {
    margin: 20px 10px;
  }
`;
const TShimmer = styled.div`
  display: flex;
  box-sizing: border-box;
`;

export const GCardShimmer = (props) => {
  const { width } = useWindowSize();

  return (
    <CardShimmer>
      <div style={{ boxSizing: "border-box" }}>
        {props.type === "single" ? (
          <React.Fragment>
            <Skeleton
              style={{ background: props.color || "#f6f7f8" }}
              variant="rect single"
              height={props.height || 90}
              animation="wave"
            />
            <Skeleton
              style={{ background: props.color || "#f6f7f8" }}
              variant="rect single"
              height={props.height || 90}
              animation="wave"
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Skeleton
              variant="rect"
              height={props.height || 120}
              animation="wave"
              style={{
                width: width <= 767 ? "calc(100% - 16px)" : "calc(50% - 16px)",
                background: props.color || "#f6f7f8",
              }}
            />
            <Skeleton
              variant="rect"
              height={props.height || 120}
              animation="wave"
              style={{
                width: width <= 767 ? "calc(100% - 16px)" : "calc(50% - 16px)",
                background: props.color || "#f6f7f8",
              }}
            />
            <Skeleton
              variant="rect"
              height={props.height || 120}
              animation="wave"
              style={{
                width: width <= 767 ? "calc(100% - 16px)" : "calc(50% - 16px)",
                background: props.color || "#f6f7f8",
              }}
            />
            <Skeleton
              variant="rect"
              height={props.height || 120}
              animation="wave"
              style={{
                width: width <= 767 ? "calc(100% - 16px)" : "calc(50% - 16px)",
                background: props.color || "#f6f7f8",
              }}
            />
          </React.Fragment>
        )}
      </div>
    </CardShimmer>
  );
};

export const GRoundShimmer = (props) => {
  return (
    <RoundShimmer>
      <Skeleton variant="circular" width={60} height={60} />
      <Skeleton variant="circular" width={60} height={60} />
      <Skeleton variant="circular" width={60} height={60} />
    </RoundShimmer>
  );
};

export const GLineShimmer = (props) => {
  return (
    <>
      <Skeleton variant="text" height={50} style={{ margin: "0 8px 25px" }} />
    </>
  );
};

export function GItemInfoShimmer(props) {
  return (
    <>
      <Skeleton
        variant="text"
        height={50}
        style={{ width: "50%", minWidth: "250px" }}
      />
      <Skeleton
        variant="text"
        height={50}
        style={{ width: "35%", minWidth: "180px" }}
      />

      <TShimmer
        style={{
          display: "flex",
          gap: "10px",
          flexDirection: "row",
          margin: "10px 0 30px",
        }}
      >
        <Skeleton
          style={{ flex: "30%" }}
          variant="rect single"
          height={210}
          animation="wave"
        />
        <GView
          style={{
            flex: "1",
            display: "flex",
            gap: "10px",
            flexDirection: "column",
          }}
        >
          <Skeleton
            variant="rect single"
            height={100}
            animation="wave"
            style={{ width: "100%" }}
          />
          <Skeleton
            variant="rect single"
            height={100}
            animation="wave"
            style={{ width: "100%" }}
          />
        </GView>
      </TShimmer>
      <TShimmer
        style={{
          display: "flex",
          gap: "10px",
          flexDirection: "row",
          margin: "10px 0",
        }}
      >
        <GView style={{ flex: "35%" }}>
          <Skeleton
            variant="text"
            height={50}
            style={{ width: "35%", minWidth: "180px" }}
          />

          <Skeleton variant="text" height={30} style={{ width: "90%" }} />
          <Skeleton variant="text" height={30} style={{ width: "90%" }} />
          <Skeleton variant="text" height={30} style={{ width: "70%" }} />
        </GView>
        <Skeleton
          style={{ flex: 1, margin: "30px" }}
          variant="rect single"
          height={250}
          animation="wave"
        />
      </TShimmer>
    </>
  );
}
