export function getGuestDisplay(unitFilter, settings) {
  let users = unitFilter.reduce(
    (n, { users }) => parseInt(n) + parseInt(users),
    0
  );
  let user_text = users == 1 ? "Guest" : "Guests";

  let units = unitFilter.length;
  let unit_text = settings?.unit_display;

  if (settings?.multiple_unit_booking == 1) {
    return `${units} ${unit_text}, ${users} ${user_text}`;
  }

  return `${users} ${user_text}`;
}

export function getGuestArray(settings) {
  let from = settings?.guest_from;
  let to = settings?.guest_to;

  let a = [];

  for (let index = from; index <= to; index++) {
    a = [...a, index];
  }
  return a;
}
