import React from "react";
import { View, TouchableOpacity, Image } from "react-native";
import GText from "../Elements/GText";

const Placeholder = ({ type, action }) => {
  const data = PlaceholderData[type] || PlaceholderData["other"];

  return (
    <View
      style={{
        padding: 30,
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 10,
        paddingBottom: 100,
        flex: 1,
      }}
    >
      {data.icon && (
        <Image
          source={data.icon?.default}
          style={{
            height: 130,
            width: 225,
            marginBottom: 30,
          }}
        />
      )}
      {data.title != "" && (
        <GText
          g4
          bold
          dark
          text={data.title}
          style={{ marginBottom: 5, textAlign: "center" }}
        />
      )}
      <GText
        g5
        text={data.subtitle}
        style={{ textAlign: "center", color: "#666" }}
      />
      {data.button && (
        <TouchableOpacity
          onPress={() => (action ? action() : null)}
          style={{
            padding: 40,
            paddingVertical: 7,
            backgroundColor: "white",
            marginTop: 20,
            borderRadius: 30,
            borderWidth: 1,
            borderColor: "#ccc",
          }}
        >
          <GText g3 text={data.button.title} />
        </TouchableOpacity>
      )}
    </View>
  );
};

export default Placeholder;

const PlaceholderData = {
  coupon: {
    icon: require("../assets/images/placeholders/e-coupon.png"),
    title: "NO OFFERS AVAILABLE",
    subtitle: "Please come back to later to check available offers.",
  },
  address: {
    icon: require("../assets/images/placeholders/e-address.png"),
    title: "NO ADDRESSES SAVED YET",
    subtitle:
      "You can add your Home, Office or Other address details here for faster checkout.",
  },
  faqs: {
    icon: require("../assets/images/placeholders/e-faq.png"),
    title: "NO FAQs YET",
    subtitle: "We will add FAQ here soon. \nBear with us :)",
  },
  order: {
    icon: require("../assets/images/placeholders/e-order.png"),
    title: "NO ORDERS PLACED",
    subtitle:
      "Looks like you havn’t tried us yet! Trust us, its worth trying :) ",
  },
  reviews: {
    icon: require("../assets/images/placeholders/e-review.png"),
    title: "NO REVIEWS YET",
    subtitle:
      "Looks like no one have reviewed this store yet. You can be the first one!",
  },
  wallet: {
    icon: require("../assets/images/placeholders/e-wallet.png"),
    title: "NO TRANSACTIONS",
    subtitle:
      "Looks like there is nothing in your wallet yet. Come back later please.",
  },
  other: {
    icon: require("../assets/images/placeholders/e-other.png"),
    title: "NO RECORDS",
    subtitle: "No data available yet.",
  },
  substitute: {
    icon: null,
    title: "",
    subtitle: "No Products Available.",
  },
  internet: {
    icon: require("../assets/images/placeholders/e-internet.png"),
    title: "NETWORK FAILED",
    subtitle: "Please check your internet connection.",
    button: {
      title: "Retry",
    },
  },
};
