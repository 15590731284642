import React, { useState, useEffect } from "react";
import { PaymentApiService } from "../services";
import { useSnackbar } from "notistack";

export default function WebPaymentCheckout(props) {
  const [selectedGateway] = useState(props.selectedGateway);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let newwindow = null;

    function showModalPopUp() {
      var width = 1000;
      var height = 800;
      //half the screen width minus half the new window width (plus 5 pixel borders).
      let leftPosition = window.screen.width / 2 - (width / 2 + 10);
      //Allow for title and status bars.
      let topPosition = window.screen.height / 2 - (height / 2 + 50);

      // newwindow = window.open(
      //   props.tokenData.payment_page,
      //   "paymentPage",
      //   "status=no,height=" +
      //     height +
      //     ",width=" +
      //     width +
      //     ",resizable=yes,left=" +
      //     leftPosition +
      //     ",top=" +
      //     topPosition +
      //     ",screenX=" +
      //     leftPosition +
      //     ",screenY=" +
      //     topPosition,
      //     "modal=yes"
      // );

      newwindow = window.open(
        props.tokenData.payment_page,
        "status=no,height=" +
          height +
          ",width=" +
          width +
          ",resizable=yes,left=" +
          leftPosition +
          ",top=" +
          topPosition +
          ",screenX=" +
          leftPosition +
          ",screenY=" +
          topPosition,
        "modal=yes"
      );

      if (
        !newwindow ||
        newwindow.closed ||
        typeof newwindow.closed == "undefined"
      ) {
        enqueueSnackbar(
          "POPUP BLOCKED. Go to settings and disable Block Pop-ups for this browser."
        );
        //POPUP BLOCKED
        return;
      }

      if (window.focus) {
        newwindow.focus();
      }

      console.log(newwindow);
      var leftDomain = false;

      const interval = setInterval(() => {
        console.log("This will run every second!");
        checkChild();
      }, 1000);

      function checkChild() {
        if (newwindow.closed) {
          console.log("Child window closed");
          props.setWebPaymentVisible(false);
          clearInterval(interval);
        }
        try {
          // console.log(window.location.href);
          // console.log(newwindow.location.href);
          if (newwindow.location.href === window.location.href) {
            if (newwindow.document.readyState === "complete") {
              // we're here when the child window returned to our domain
              newwindow.close();
              clearInterval(interval);
              paymentConfirmation();
              //child.postMessage({ message: "requestResult" }, "*");
            }
          } else {
            // this code should never be reached,
            // as the x-site security check throws
            // but just in case
            leftDomain = true;
          }
        } catch (e) {
          // we're here when the child window has been navigated away or closed
          if (newwindow.closed) {
            clearInterval(interval);
            props.setWebPaymentVisible(false);
            return;
          }
          // navigated to another domain
          leftDomain = true;
        }
      }
    }

    showModalPopUp();
  }, []);

  function paymentConfirmation() {
    PaymentApiService({
      method: "GET",
      route: props.tokenData.verify_url,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.onSuccessPayment();
      } else {
        return;
      }
    });
  }

  return <div id="divBackground"></div>;
}
