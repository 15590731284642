import React, { useState, useContext } from "react";
import styled from "styled-components";
import { ImgUrl, addDefaultSrc } from "../services";
import GText from "./GText";
import GView from "./GView";

const StoreBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-shadow: -1px -1px 10px #bbbbbb5c;
  padding: 10px;
  img {
    // height: 170px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #f2f2f2;
  }
  .rating {
    display: inline-block;
    float: right;
    p {
      margin: 2px 0 2px 8px;
      font-size: 14px;
      display: flex;
      align-items: baseline;
      svg {
        width: 12px;
        fill: #333;
      }

      &.tag {
        background: ${({ theme }) => theme.body};
        color: #fff;
        padding: 2px 8px;
        font-size: 12px;
      }
    }
  }

  .closed {
    background: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    position: absolute;
    top: 1px;
    left: 1px;
    text-align: center;
    padding-top: 75px;
    margin: 10px;
    width: calc(100% - 20px);
    z-index: 1;
    height: calc(170px - 75px);
    font-weight: 600;
  }
`;

export default function GItemListView(props) {
  const [itemData] = useState(props.data);
  return (
    <StoreBox style={{ cursor: props.data?.status === 0 ? "auto" : "pointer" }}>
      <GView style={{ position: "relative" }}>
        {props.settings?.product == "vehicle" && (
          <GText
            g6
            text={props.data?.header}
            style={{
              background: "#f5f5f5",
              width: "auto",
              position: "absolute",
              padding: "2px 10px",
              top: "0",
              right: "0",
            }}
          />
        )}
        <img
          src={
            props.data?.images.length > 0
              ? ImgUrl("item") + "/" + props.data?.images[0]?.photo
              : ""
          }
          alt={props.data?.title}
          onError={(e) => addDefaultSrc(e, "item")}
          onClick={() => {
            if (itemData?.status === 0) {
              return;
            }
            props.onSelectItem(itemData);
          }}
          width="auto"
          height={props.settings?.product == "vehicle" ? "190px" : "170px"}
          style={{ width: "100%" }}
        />
      </GView>
      <div
        className="info"
        onClick={() => {
          if (itemData?.status === 0) {
            return;
          }
          props.onSelectItem(itemData);
        }}
      >
        <div className="rating">
          {props.data?.rating?.count == 0 ? (
            <p className="tag">{props.data?.rating?.display}</p>
          ) : (
            props.settings?.product != "vehicle" && (
              <p onClick={() => props.onShowReview(itemData)}>
                ★ {props.data?.rating?.display}({props.data?.rating?.count})
              </p>
            )
          )}
        </div>

        <GText
          g4
          semi
          text={props.data?.custom_fields?.title || props.data?.title}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            // whiteSpace: "nowrap",
            padding: "0 5px",
            fontSize: "16.5px",
            lineHeight: "22px",
            letterSpacing: "0.3px",
          }}
        />

        <GText
          med
          text={props.data?.custom_fields?.desc}
          style={{
            margin: "3px 0",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            padding: "0 5px",
            fontSize: "14px",
          }}
        />
        {props.settings?.time_charges != 0 && (
          <GText
            text={props.settings?.currency_symbol + props.data?.cost}
            semi
            style={{
              margin: "5px 0",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              padding: "0 5px",
            }}
          />
        )}
      </div>

      {props.data?.status === 0 ? <div className="closed">Closed</div> : ""}
    </StoreBox>
  );
}
