import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import "./assets/fonts/Lato/latofonts.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { SettingsProvider } from "./DataFactory/useSettings";
import { UserDefaultLocation } from "./DataFactory/useLocation";
import { UserDefaultInfo } from "./DataFactory/useSearchInfo";

ReactDOM.render(
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
  >
    <SettingsProvider>
      <UserDefaultLocation>
        <UserDefaultInfo>
          <Router>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </Router>
      </UserDefaultInfo>
      </UserDefaultLocation>
    </SettingsProvider>
  </SnackbarProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
