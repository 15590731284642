import React, { useEffect } from "react";
import { Text, TouchableOpacity, View } from "react-native-web";
import GText from "./GText";
import useSettings from "../DataFactory/useSettings";

const GTextButton = (props) => {
  const { title, style, titleStyle, onPress } = props;
  const { settings } = useSettings();

  return (
    <TouchableOpacity style={style} onPress={onPress}>
      <GText
        bold
        style={{ color: settings?.web?.web_color, padding: 10 }}
        text={title}
      />
    </TouchableOpacity>
  );
};

export default GTextButton;
