import React, { useState, useEffect, useContext } from "react";
import { SettingsContext } from "../DataFactory/useSettings";
import pointsEarn from "../assets/images/point-earn.png";
import pointsNotEarn from "../assets/images/point-not-earn.png";
import GButton from "../Elements/GButton";
import GText from "../Elements/GText";
import useDataFctory from "../useDataFactory";
import useWindowSize from "../DataFactory/useWindowSize";
import GView from "../Elements/GView";

export default function Wallet() {
  const { settings } = useContext(SettingsContext);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const { width } = useWindowSize();

  const {
    loading,
    TableShimmer,
    Placeholder,
    data,
    loadMore,
    pagination,
    refreshData,
  } = useDataFctory("wallet", true);

  useEffect(() => {
    setBtnDisabled(false);
  }, [data]);

  return (
    <React.Fragment>
      <GText g2="true" theme bold text={"Wallet"} />
      <GView
        style={{
          boxShadow: "0 0 10px #cccccc85",
          padding: "20px 0 ",
          margin: "10px 0",
        }}
      >
        <GView
          style={{
            display: "flex",
            alignItems: "start",
            flexDirection: width <= 767 ? "column" : "row",
          }}
        >
          <GView
            style={{
              padding: "10px 25px 20px",
              minWidth: width <= 991 ? "150px" : "200px",
            }}
          >
            <GText
              h5
              med
              text={"Available Balance"}
              style={{
                margin: "10px 0",
                letterSpacing: "0",
              }}
            />
            <GText
              g3
              semi
              text={settings?.currency_symbol + settings?.user?.balance}
            />
          </GView>
          <GView
            style={{
              flex: "1 1 1%",
              borderLeft: width <= 767 ? "0" : "1px solid #f2f2f2",
              borderTop: width <= 767 ? "1px solid #f2f2f2" : "0",
              width: width <= 767 ? "100%" : "auto",
            }}
          >
            {loading === true ? (
              <GView style={{ padding: "0 20px" }}>
                <TableShimmer></TableShimmer>
              </GView>
            ) : data.length === 0 ? (
              <Placeholder />
            ) : (
              <>
                <GText
                  dark
                  text={`${pagination.total} Transactions`}
                  style={{
                    margin: "10px",
                    padding: "10px 15px",
                  }}
                />
                <GView
                  style={{
                    maxHeight: "600px",
                    overflow: "auto",
                    padding: "0 15px",
                  }}
                >
                  {data.map((w, i) => {
                    return (
                      <GView
                        key={i}
                        style={{
                          margin: "10px",
                          padding: "8px 0",
                          borderBottom: "1px solid #f2f2f2",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <img
                          src={w.amount >= 0 ? pointsEarn : pointsNotEarn}
                          alt=""
                          style={{
                            width: "18px",
                            height: "17px",
                            paddingRight: "15px",
                            verticalAlign: "top",
                            paddingTop: "5px",
                          }}
                        />
                        <GView
                          style={{
                            width: "100%",
                          }}
                        >
                          <GText
                            g6
                            med
                            text={w.created}
                            style={{ float: "right" }}
                          />
                          <GText g5 semi text={w.amount} />
                          <GText
                            dark
                            text={w.title}
                            style={{ margin: "3px 0" }}
                          />
                        </GView>
                      </GView>
                    );
                  })}
                  {pagination.next_page_url ? (
                    <GButton
                      loading={btnDisabled}
                      variant="condensed"
                      children="Load More"
                      type="button"
                      onClick={() => {
                        setBtnDisabled(true);
                        loadMore();
                      }}
                      style={{
                        margin: "20px auto 10px",
                        width: "150px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </GView>
              </>
            )}
          </GView>
        </GView>
      </GView>
    </React.Fragment>
  );
}
