import React from "react";
import GButton from "../Elements/GButton";
import { ApiService } from "../services";
import GStarRating from "../Elements/gStarRating";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useSnackbar } from "notistack";
import GText from "../Elements/GText";

const FeedbackDiv = styled.div`
  text-align: center;
  p.theme {
    color: ${({ theme }) => theme.body};
  }

  // p {
  //   font-size: 13px;
  //   padding: 5px 15px;
  //   color: #555;
  // }
  .star-rating {
    margin: 0 15px 10px;
    .star {
      width: 25px;
      height: 25px;
    }
  }
`;

export default function OrderFeedback(props) {
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = {
    rating: "",
    review: "",
    order_id: props.orderId,
  };
  const onSubmit = (values) => {
    ApiService({
      method: "POST",
      route: "c/order/feedback",
      body: values,
    }).then((response) => {
      console.log("r" + response.data.status_code);
      if (response.data.status_code === 1) {
        props.onSubmit();
        props.setOfvisible(false);
      }
      enqueueSnackbar(response.data.message);
    });
  };

  if (props.ofvisible === false) {
    return null;
  }

  const validationSchema = Yup.object({
    rating: Yup.string().required("Required"),
  });

  return (
    <div className="modal-body">
      <FeedbackDiv>
        <GText g5 semi className="theme" text={"Booking Feedback"} />
        <GText text={"Rate on the scale of 1-5"} style={{ margin: "10px 0" }} />
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GStarRating
                totalStars={5}
                selectedStars={0}
                selected={true}
                onSelect={(val) => {
                  values.rating = val;
                  setFieldValue("rating", val);
                }}
              />

              <FormikControl
                control="input"
                as="textarea"
                placeholder="Enter Feedback..."
                name="review"
              />

              <GButton
                variant="condensed"
                disabled={!values.rating ? true : false}
                children="Submit"
                type="submit"
                style={{ width: "94%", margin: "10px 15px 15px" }}
              />
            </Form>
          )}
        </Formik>
      </FeedbackDiv>
    </div>
  );
}
